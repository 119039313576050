<template>
  <div class="mt-3">
    <CContainer class="border shadow card-height border-radius mb-5">
      <div class="row p-3 d-flex justify-content-between">
        <div
          class="col-lg-7 col-md-12 col-sm-12 col-12"
          style="display: flex; align-items: end"
        >
          <div class="text-left font-weight-bold mt-3" v-if="!isSubmitted">
            <div class="text-primary">
              Total Questions: {{ questionLength }}
            </div>
            <div>Attend Questions: {{ attendQuestions }}</div>
            <div>Remaining Questions: {{ remainingQuestions }}</div>
          </div>
          <div v-if="isSubmitted" class="text-left">
            <div class="h5">
              <span
                class="font-weight-bold text-primary"
                style="margin-right: 15px"
                >Name</span
              >:
              <span>{{ candidateProfile?.full_name | toTitleCase }}</span>
            </div>
            <div class="h5">
              <span
                class="font-weight-bold text-primary"
                style="margin-right: 12px"
                >Status</span
              >:
              <span>
                {{
                  getAssessmentTestSummary[1]?.completed
                    ? "Completed"
                    : "Pending"
                }}
              </span>
            </div>
            <div class="h5">
              <span
                class="font-weight-bold text-primary"
                style="margin-right: 15px"
              >
                Score </span
              >:
              <span>
                {{ getAssessmentTestSummary[1].total_score }}/
                {{ questionLength }}
              </span>
            </div>
            <div class="h5" v-if="unAttendedQuestions > 0">
              <span
                class="font-weight-bold text-primary"
                style="margin-right: 15px"
                >UnAttended Questions</span
              >:
              <span>{{ unAttendedQuestions || "-" }}</span>
            </div>
          </div>
        </div>

        <div
          class="col-lg-5 col-md-12 col-sm-12 col-12 content-left mt-sm-5 mt-md-5 mt-5"
        >
          <div v-if="isSubmitted">
            <CChartDoughnut :datasets="defaultDatasets" :labels="labels" />
          </div>
          <div v-if="!isSubmitted" style="width: 150px">
            <i
              class="fa-solid fa-stopwatch text-primary mr-3"
              style="font-size: 30px"
            ></i>
            <span class="m-0 h3" :style="{ color: time === -1 ? 'red' : '' }">
              {{ minutes > 9 ? minutes : `0${minutes}` }} :
              {{ seconds > 9 ? seconds : `0${seconds}` }}
            </span>
          </div>
        </div>
      </div>
      <hr />
      <ValidationObserver ref="questionForm">
        <div class="row p-3">
          <div
            class="col-lg-12"
            v-for="(question, index) in getCurrentQuestionsList"
            :key="index"
          >
            <div>
              <div class="text-black p-3 rounded bg-light">
                <!-- :class="
                  question.is_correct
                    ? 'bg-light-green'
                    : question.is_correct === false
                    ? 'bg-light-red'
                    : 'bg-light'
                " -->
                <h5>{{ `${skip + index + 1}.` }} {{ question?.question }}</h5>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <RadioButton
                    :name="`answer_${question.assessment_ques_id}_${
                      skip + index
                    }`"
                    style="font-size: 16px"
                    :options="options(question)"
                    :vertically="true"
                    :value="answers[skip + index]?.option_id"
                    @change="handleInput"
                    :disabled="isSubmitted"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <div
                :class="{
                  'border-dotted-bottom mt-3 mb-3':
                    index < getCurrentQuestionsList.length - 1,
                }"
              ></div>
            </div>
          </div>
        </div>
      </ValidationObserver>
      <div class="text-center m-5">
        <CButton
          v-if="!isSubmitted"
          class="bg-secondary"
          style="width: 130px"
          @click="previousQuestion()"
          >Back</CButton
        >
        <CButton
          v-if="skip !== 55 && !isSubmitted"
          class="ml-3 bg-primary text-white"
          style="width: 130px"
          @click="nextQuestion()"
          >Next</CButton
        >
        <CButton
          v-if="skip === 55 && !isSubmitted"
          class="ml-3 bg-primary text-white"
          style="width: 130px"
          @click="onSubmit()"
          >Submit</CButton
        >
        <CButton
          v-if="isSubmitted"
          class="ml-3 bg-primary text-white"
          style="width: 130px"
          @click="navigateToHome()"
          >Home</CButton
        >
      </div>
    </CContainer>
    <Modal
      :modalTitle="confirmModal.modalTitle"
      :modalColor="confirmModal.modalColor"
      :modalContent="confirmModal.modalContent"
      :isShowPopup="confirmModal.isShowPopup"
      :buttons="confirmModal.buttons"
      :modalCallBack="modalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import RadioButton from "@/components/reusable/Fields/RadioButton";
import { mapActions, mapGetters } from "vuex";
import { CChartDoughnut } from "@coreui/vue-chartjs";
import Modal from "@/components/reusable/Modal";

export default {
  components: {
    RadioButton,
    CChartDoughnut,
    Modal,
  },
  data() {
    return {
      skip: 0,
      limit: 5,
      questionsList: [],
      questionLength: 0,
      answers: [],
      time: 300,
      minutes: 0,
      seconds: 0,
      answerList: [],
      isSubmitted: false,
      isTimeoutSubmit: false,
      unAttendedQuestions: 0,
      confirmModal: {
        modalColor: "primary",
        modalTitle: "NOTIFICATION",
        modalContent: "Your Test is Submitted Successfully!",
        isShowPopup: false,
        buttons: ["Ok"],
      },

      // Doughnut chart data
      labels: ["Correct Answers", "Wrong Answers"],
      // options: {},
    };
  },
  watch: {
    time(val) {
      if (val === 0 && !this.isSubmitted) {
        this.isTimeoutSubmit = true;
        this.onSubmit().then(() => {
          if (this.isSubmitted) {
            this.confirmModal.isShowPopup = true;
          }
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      "getInterviewQuestions",
      "getAssessmentTestId",
      "getAssessmentTestSummary",
      "candidateProfile",
    ]),
    defaultDatasets() {
      return [
        {
          backgroundColor: ["#2eb85c", "#ff383f"],
          data: [this.correctAnswers, this.wrongAnswers],
          borderWidth: 3,
        },
      ];
    },
    correctAnswers() {
      return this.answerList.filter((v) => v.is_correct === true).length || 5;
    },
    wrongAnswers() {
      return this.answerList.filter((v) => v.is_correct === false).length || 0;
    },
    getCurrentQuestionsList() {
      if (!this.isSubmitted) {
        return this.getInterviewQuestions || [];
      } else if (this.isSubmitted) {
        return this.answerList || [];
      }
    },
    attendQuestions() {
      return (
        this.answers.filter(
          (v) => v.option_id !== null || v.question_id !== null
        ).length ?? 0
      );
    },
    remainingQuestions() {
      return this.questionLength - this.attendQuestions || 0;
    },
    countDown() {
      setInterval(() => {
        if (this.time > -1) {
          this.minutes = Math.floor(this.time / 60);
          this.seconds = this.time % 60;
          this.time--;
        }
      }, 1000);
    },
  },
  methods: {
    ...mapActions([
      "fetchInterviewQuestions",
      "showToast",
      "createAssessmentTestSummary",
    ]),
    handleInput(name, value) {
      const [_name, question_id, index] = name.split("_");
      if (!this.answers[index]) {
        this.$set(this.answers, index, {
          option_id: null,
          question_id: null,
        });
      }
      this.answers[index].option_id = value.code;
      this.answers[index].question_id = parseInt(question_id);
    },
    async nextQuestion() {
      this.$refs.questionForm.reset();
      if (
        this.questionLength &&
        this.skip < this.questionLength - 1 &&
        this.skip >= 0
      ) {
        this.skip += 5;
        await this.fetchQuestions();
      }
    },
    async previousQuestion() {
      this.$refs.questionForm.reset();
      if (
        this.questionLength &&
        this.skip <= this.questionLength &&
        this.skip > 0
      ) {
        this.skip -= 5;
        await this.fetchQuestions();
      }
    },
    options(question) {
      return question.option_texts.map((v) => {
        return {
          code: v?.option_id,
          label: v?.option,
          correct:
            v?.option_id === question?.option_id ? question?.is_correct : null,
        };
      });
    },
    async fetchQuestions() {
      const payload = {
        skip: this.skip,
        limit: this.limit,
      };
      await this.fetchInterviewQuestions(payload);
    },
    async onSubmit() {
      if (!this.isTimeoutSubmit) {
        const isValid = await this.$refs.questionForm.validate();
        if (!isValid) {
          this.showToast({
            class: "bg-danger text-white",
            message: "Please fill mandatory fields!",
          });
          return;
        }
      }

      const payload = {
        assessment_test_id: this.$route.params.assessment_id,
        summary: this.answers.filter(
          (v) => v.option_id && v.question_id !== null
        ),
      };
      this.$refs.questionForm.reset();

      // need to change
      this.skip = 0;
      const query = {
        skip: this.skip,
        limit: 60,
      };
      await this.fetchInterviewQuestions(query);
      // end

      await this.createAssessmentTestSummary(payload).then((res) => {
        if (res.status === 200) {
          this.answerList = this.getInterviewQuestions.map((v) => {
            const matchingQuestion = this.getAssessmentTestSummary[0].find(
              (question) => question.question_id === v.assessment_ques_id
            );
            if (!matchingQuestion) {
              this.unAttendedQuestions++;
            }
            return {
              assessment_ques_id: v.assessment_ques_id,
              question: v.question,
              option_texts: v.option_texts,
              is_correct: matchingQuestion?.is_correct,
              summary_id: matchingQuestion?.summary_id,
              option_id: matchingQuestion?.option_id,
            };
          });
          this.isSubmitted = true;
        }
      });
    },
    navigateToHome() {
      this.$router.push("/home");
    },
    modalCallBack(action) {
      this.confirmModal.isShowPopup = false;
    },
  },
  async mounted() {
    await this.fetchQuestions();
    // const query = {
    //   fetch_row_count: true
    // }
    // await this.fetchInterviewQuestions(query);

    // need to change
    this.questionLength = 60;
    //
    this.countDown;
  },
  filters: {
    toTitleCase(data) {
      return data ? _.startCase(data) : data;
    },
  },
};
</script>

<style scoped>
.border-radius {
  border-radius: 15px;
}
.card-height {
  min-height: 400px;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-left {
  display: flex;
  justify-content: flex-end;
}
.inline-item {
  display: inline-block;
  margin-right: 20px;
}
/* .content-bottom {
  display: flex;
  align-items: end;
} */
</style>
